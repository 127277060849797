body {
  margin: 0;
  font-family: "Saira Semi Condensed";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  max-width: 100vw;
}

code {
  font-family: "Saira Semi Condensed", sans-serif
    monospace;
}