.aboutme {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    background: rgb(243,237,234);
    box-shadow: 0px 0px 15px rgba(0,0,0,0.5)
}

.aboutme-contents {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.aboutme-description-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    width: 100%;
}

.aboutme-description-title {
    font-size: 4.0em;
    text-shadow: 0px 0px 2px rgba(0,0,0,0.3);
    text-align: center;
    margin: 0;
}

.aboutme-hand {
    height: 5em;
    margin-left: 20px;
}

.aboutme-description-subtitle {
    font-size: 2em;
    font-weight: 400;
    font-stretch: condensed;
    text-shadow: 0px 0px 2px rgba(0,0,0,0.3);
}

.aboutme-description-main {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10%;
    font-size: 1.3em;
    font-weight: 400;
    text-shadow: 0px 0px 1px rgba(0,0,0,0.3);
}
.aboutme-skill {
    height: 1.3em;
    margin: 0.2em;
    background: white;
    padding: 0.1em 0.2em;
}

.aboutme-description-contents {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    width: 45%;
    text-align: left;
}

.aboutme-contact-contents {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 45%;
    text-align: center;
}

.aboutme-description-container {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 3%;
    padding-bottom: 5%;
    width: 70%;
    min-height: 50vh;
    justify-content: space-evenly;
}

.toggle-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 80px;
    font-size: 1.7em;
    background: black;
    color: white;
}

.toggle-footer :hover {
    cursor: pointer;
}

.footer-icon {
    margin-left: 20px;
    height: 30px;
    filter: invert(100%)
}

.button {
    height: 40px;
    width: auto;
    padding: 5px 10px;
}

.button :hover {
    cursor: pointer;
}

.button-image {
    object-fit: cover;
    height: 100%;
}

.skill-icon {
    object-fit: contain;
    max-height: 40vh;
    max-width: 80%;
}

@media only screen and (max-width: 800px) {
    .aboutme-description-contents {
        width: 90%;
    }
    .aboutme-contact-contents {
        width: 90%;
        height: 25vh;
        text-align: center;
    }
    .skill-icon {
        object-fit: contain;
        max-height: 15vh;
        max-width: 70%;
    }
    .aboutme-skill {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.1em;
        margin: 0.2em;
        background: white;
        padding: 0.1em 0.2em;
    }
    .aboutme-description-title {
        font-size: 2.3em;
        text-align: center;
        padding-left: 0;
        margin-bottom: 15px;
    }
    .aboutme-description-subtitle {
        font-size: 1.4em;
        font-weight: 400;
        text-align: center;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .aboutme-description-main {
        font-size: 1.1em;
        justify-content: center;
        margin-right: 0;
    }
    .aboutme-description-container {
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
        width: 90%;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
    }
    .aboutme-hand {
        height: 3em;
        margin-left: 10px;
        margin-bottom: 10px;
    }
    .toggle-footer {
        font-size: 1.5em;
        height: 60px;
    }
    .footer-icon {
        margin-left: 10px;
        height: 20px;
        filter: invert(100%)
    }
}
@media only screen and (max-width: 800px) and (max-height: 300px) {
    .aboutme-description-contents {
        width: 45%;
        min-height: 80vh;
    }
    .aboutme-contact-contents {
        width: 45%;
        min-height: 80vh;
        text-align: center;
    }
    .skill-icon {
        object-fit: contain;
        max-height: 100%;
        max-width: 100%;
    }
    .aboutme-description-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-evenly;
        align-items: center;
    }
}